/**
 * Reference https://codepen.io/attilahajzer/pen/kydqJ 
 */

/* Turn off BS for keyboard layout */
.keyboard, .keyboard::after, .keyboard::before,
.key, .key::after, .key::before {
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  box-sizing: content-box !important;
}

.keyboard {
  width: 650px;
  height: 220px;
  background-color: #9b9b9b;
  margin: 0px auto;
  border-radius: 9px;
  padding: 30px;
  color: #eee;
}

.key {
  width: 40px;
  height:40px;
  display:block;
  text-align: left;
  padding-left: 8px;
  line-height: 29px;
  border: 1px solid grey;
  border-radius:2px;
  float:left; margin-left: 2px;
  margin-bottom:2px;
  cursor: pointer;
  transition: box-shadow 0.7s ease;
}

.natural {
  color: black;
  background-color: white;
}

.sharp-or-flat {
  background-color:#333;
  color: #fff;
}

.null {
  cursor: disabled;
  background-color: #9b9b9b;
  color: grey;
}

.function{font-size: 12px; width:30px; height:30px; margin-bottom:15px;}
.small{font-size:10px; line-height:13px; text-align: center; padding:0 5px; padding-top:2px; height:28px !important;}
.space1{margin-right: 43px !important;}
.space2{margin-right: 25px !important;}
.dual {font-size: 14px; line-height: 20px; width:30px; }
.backspace {width:84px; font-size: 12px;}
.tab {width: 50px; line-height: 40px; font-size:13px;}
.letter{width:30px;}
.slash{width:64px;}
.caps{width:70px; font-size:12px; line-height:18px;}
.enter{width:92px; line-height:40px; text-align: center; padding-left:0;}
.shift.left{width: 90px;line-height: 40px; font-size:13px;}
.shift.right{width: 104px;line-height: 40px; font-size:13px;}
.ctrl{width: 50px; line-height: 40px; font-size:13px;}
.space{width:234px;}
.arrows{position:relative; top:42px;}
.sec-func .key{width: 32px; font-size:10px; text-align:left; line-height:40px; float:left;}
.sec-func div.dual{line-height:20px;}
.arrows .key{text-align: center; padding-left: 7px; margin-left:2px;}
.hidden{visibility: hidden;}

.natural:hover, .sharp-or-flat:hover{
  box-shadow:0px 0px 10px #d27f1e;
  z-index:1000;
}

.playing {
  box-shadow:0px 0px 10px #d27f1e;
  z-index:1000;
}
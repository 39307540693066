html, body {
    height: 100%;
  }
  
  body {
    /* font-family: 'Courier Prime'; */
    background:  #2f3338;
    color: #fff;
    display: flex;
    flex-direction: column;
  }
  
  /* Turn off browser shit */
  button, input[type="submit"], input[type="reset"] {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
  }
  
  a {
    color: #fff;
  }
  
  a:hover {
    color: #d27f1e;
  }
  
  .center-children {
    text-align: center;
  }
  
  .center-h {
    margin: 0 auto;
  }
  
  .center-v {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .color-white {
    color: white;
  }
  
  .content {
    flex: 1 0 auto;
  }
  
  .digital {
    font-family: 'Orbitron';
    color: #d27f1e;
  }
  
  .ditial-btn {
    background-color: #2f3338;
    padding: 10px;
  }
  
  .digital-outline {
    border: 1px solid #d27f1e;
    border-radius: 10px;
  }
  
  /* Customize the label (the container) */
  .digital-radio {
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .digital-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom radio button */
  .digital-radio-light {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: red;
    border-radius: 10px;
    margin-top: 5px;
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75) inset;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75) inset;
    -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75) inset;
  }
  
  /* On mouse-over, add a grey background color */
  .digital-radio:hover input ~ .digital-radio-light {
    background-color: #d27f1e;
    box-shadow: 0px 0px 10px 5px rgba(210,127,30,0.75);
    -webkit-box-shadow: 0px 0px 10px 5px rgba(210,127,30,0.75);
    -moz-box-shadow: 0px 0px 10px 5px rgba(210,127,30,0.75);
  }
  
  /* When the radio button is checked, add a blue background */
  .digital-radio input:checked ~ .digital-radio-light {
    background-color: #d27f1e;
    box-shadow: 0px 0px 10px 5px rgba(210,127,30,0.75);
    -webkit-box-shadow: 0px 0px 10px 5px rgba(210,127,30,0.75);
    -moz-box-shadow: 0px 0px 10px 5px rgba(210,127,30,0.75);
  }
  
  /* Customize the label (the container) */
  .digital-switch {
   display: block;
   position: relative;
   padding-left: 30px;
   cursor: pointer;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .digital-switch input {
   position: absolute;
   opacity: 0;
   cursor: pointer;
   height: 0;
   width: 0;
  }
  
  /* Create a custom checkbox */
  .digital-switch-light {
   position: absolute;
   top: 0;
   left: 0;
   height: 15px;
   width: 15px;
   background-color: red;
   border-radius: 10px;
   margin-top: 5px;
   box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75) inset;
   -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75) inset;
   -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75) inset;
  }
  
  /* When the checkbox is checked */
  .digital-switch input:checked ~ .digital-switch-light {
   background-color: #d27f1e;
   box-shadow: 0px 0px 10px 5px rgba(210,127,30,0.75);
   -webkit-box-shadow: 0px 0px 10px 5px rgba(210,127,30,0.75);
   -moz-box-shadow: 0px 0px 10px 5px rgba(210,127,30,0.75);
  }
  
  .field {
    background-color: black;
    height: 100%;
    width: 100%;
    min-height: 80vh;
  }
  
  .footer {
    flex-shrink: 0;
    padding-bottom: 20px;
  }
  
  .hover {
    cursor: pointer;
  }
  
  .inline-block {
    display: inline-block;
  }
  
  .input-slider:hover, .input-knob:hover {
    cursor: pointer;
  }
  
  .joystick {
    cursor: move;
    background-color: #d27f1e;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    position: absolute;
    top: 70%;
    box-shadow: 5px -5px 5px 3px rgba(0,0,0,0.45) inset;
    -webkit-box-shadow: 5px -5px 5px 3px rgba(0,0,0,0.45) inset;
    -moz-box-shadow: 5px -5px 5px 3px rgba(0,0,0,0.45) inset;
  }
  
  .joystick-container {
    overflow: hidden;
    height: 80vh;
    width: 100%;
    box-shadow: 10px -5px 25px 5px rgba(0,0,0,0.75) inset;
    -webkit-box-shadow: 10px -5px 25px 5px rgba(0,0,0,0.75) inset;
    -moz-box-shadow: 10px -5px 25px 5px rgba(0,0,0,0.75) inset;
  }
  
  .light-grey {
    color: #b0b0b0;
  }

  .link-button {
    background-color: #d27f1e;
    padding: 1em 3em;
    text-decoration: none;
    border-radius: 3em;
  }

  .link-button:active {
    background-color: white;
  }

  .link-button:hover {
    background-color: white;
  }
  
  .logo {
    font-size: 2em;
    margin: 0 10px;
    color: #d27f1e;
  }
  
  .mb-10 {
    margin-bottom: 10px;
  }
  
  .mb-20 {
    margin-bottom: 20px;
  }
  
  .mb-30 {
    margin-bottom: 30px;
  }
  
  .mh-10 {
    margin: 0 10px;
  }
  
  .mh-12 {
    margin: 0 12px;
  }
  
  .mr-20 {
    margin-right: 20px;
  }
  
  .no-margin {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .orange {
    color: #d27f1e;
  }
  
  .pa-10 {
    padding: 10px;
  }
  
  .pa-20 {
    padding: 20px;
  }
  
  .pl-30 {
    padding-left: 30px;
  }
  
  .pl-80 {
    padding-left: 80px;
  }
  
  .pt-20 {
    padding-top: 20;
  }

  .album {

  }

  .album-art {
    max-width: 100%;
  }

  .album-description {
    /* height: 40px; */
  }

  .album-embed {
    /* max-width: 300px; */
  }

  .album-title {
    font-size: 1.2em;
  }

  .photo {
    max-width: 100%;
  }
  
  .script {
    background-color: black;
    padding: 10px;
  }
  
  .syntax {
    color: #289de3;
  }
  
  .td-none {
    text-decoration: none;
  }
  
  .td-none:hover {
    text-decoration: none;
  }
  
  .td-underline {
    text-decoration: underline;
  }
  
  .tooltip {
    padding: 2px 6px;
    font-size: 0.6rem;
  }
  
  .w-100 {
    width: 100%;
  }
  
  .xor {
    height: 450px;
    display: block;
  }
  
  @media only screen and (max-width: 600px) {
    .xor {
      height: 250px;
    }
  }